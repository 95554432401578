<template>
    <v-container class="container--fluid text--white impressum page background-image">
        <v-layout>
            <v-col class="page-container col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex flex-column container-shadow">
                <NavButton/>
                <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                    <v-row>
                        <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                            <TextAnimation upper-text="Impressum & Datenschutz" lower-text="Angaben gemäß §5 TMG"/>
                            <div class="pt-10">
                                <div class="page-content d-flex flex-row overflow-visible">
                                    <div class="page-content-text d-flex flex-column col-12 container-shadow">
                                    </div>
                                </div>
                            </div>

                        </v-col>
                    </v-row>
                </v-col>
                <Footer/>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
import TextAnimation from "../components/TextAnimation";
import Footer from "../components/Footer";
//import FileDocumentEdit from 'vue-material-design-icons/FileDocumentEdit.vue'
import NavButton from "@/components/NavButton";

export default {
    name: "Impressum",
    components: {
        NavButton,
        TextAnimation,
        //FileDocumentEdit,
        Footer
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.impressum {
    height: 100%;
}

.impressum-responsible {
    line-height: 30px !important;
    padding-bottom: 8px;
}
</style>
